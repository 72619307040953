export const exportColumns = [
  {
    label: "Name",
    value: "name",
  },
  {
    label: "Type",
    value: "campaign_type.name",
  },
  {
    label: "Start Date",
    value: "start_date",
  },
  {
    label: "End Date",
    value: "end_date",
  },
  {
    label: "Total Customer",
    value: "total_customer",
  },
  {
    label: "Total Sales",
    value: "total_sale",
  },
  {
    label: "Total Cost",
    value: "total_cost",
  },
  {
    label: "Average Sales per Customer",
    value: "average_spend_per_customer",
  },
  {
    label: "Profit per Customer",
    value: "profit_per_customer",
  },
];
