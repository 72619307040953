export const StatusOptions = [
  { label: "Active", value: "active" },
  { label: "Inactive", value: "inactive" },
];

export const SubscriptionOptions = [
  { value: "Free Trial", label: "Free Trial" },
  { value: "Agency", label: "Agency" },
  { value: "D.I.Y (Do It Yourself)", label: "D.I.Y (Do It Yourself)" },
];

export const PackageKeyOptions = [
  { value: "basic_package", label: "Basic Package" },
  { value: "special_basic_package", label: "Special Basic Package" },
  { value: "integration_package", label: "Integration Package" },
  {
    value: "special_integration_package",
    label: "Special Integration Package",
  },
  { value: "free_trial_package", label: "Free Trial" },
];
