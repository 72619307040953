import { ColumnDef } from "@tanstack/react-table";
import { useAppDispatch } from "app/hooks";
import { BreadCrumb, TableContainer } from "components";
import { getAgencyDetails, getClientsOfAgency } from "features";
import { useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Container } from "reactstrap";
import { AgencyType } from "types";
import { DeleteClient } from "../components/DeleteClient";
import { UnlinkClient } from "../components/UnlinkClient";

const AgencyDetails = () => {
  const { id } = useParams();
  const dispatch = useAppDispatch();
  const [agency, setAgency] = useState<AgencyType | null>(null);
  const [clients, setClients] = useState<AgencyType[]>([]);
  const [selectedClientId, setSelectedClientId] = useState<number | null>(null);
  const [isUnlink, setIsUnlink] = useState(false);
  const [isDelete, setIsDelete] = useState(false);
  const [trigger, setTrigger] = useState<number | null>(null);

  const getAgencyInformation = async () => {
    try {
      const agency = await dispatch(getAgencyDetails(Number(id))).unwrap();
      const clients = await dispatch(getClientsOfAgency(Number(id))).unwrap();
      setAgency(agency);
      setClients(clients);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  };

  const columns: ColumnDef<AgencyType>[] = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "id",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Name",
        accessorKey: "client_name",
        disableFilters: true,
        enableColumnFilter: false,
      },
      {
        header: "Action",
        disableFilters: true,
        enableColumnFilter: false,
        cell: ({ row }) => {
          return (
            <div>
              <Button
                color="primary"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() => {
                  setSelectedClientId(row.original.id);
                  setIsUnlink(true);
                }}
              >
                Unlink
              </Button>
              <Button
                color="danger"
                className="btn-sm ms-2"
                style={{ minWidth: 58 }}
                onClick={() => {
                  setSelectedClientId(row.original.id);
                  setIsDelete(true);
                }}
              >
                Delete
              </Button>
            </div>
          );
        },
      },
    ],
    [clients]
  );

  useEffect(() => {
    getAgencyInformation();
  }, [trigger]);

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title="Agency Management" />
          <Card>
            <CardHeader>
              <h4 className="card-title mb-0">{agency?.name}</h4>
            </CardHeader>
            <CardBody>
              <TableContainer
                columns={columns || []}
                data={clients || []}
                iscustomPageSize={false}
                customPageSize={5}
                tableClass="table-centered align-middle table-nowrap mb-0"
                theadClass="text-muted table-light"
                divClass="table-responsive"
              />
            </CardBody>
          </Card>
        </Container>
      </div>

      <UnlinkClient
        isOpen={isUnlink}
        toggle={() => setIsUnlink(!isUnlink)}
        id={Number(id)}
        client_id={selectedClientId as number}
        triggerRender={() => setTrigger(new Date().getTime())}
      />

      <DeleteClient
        isOpen={isDelete}
        toggle={() => setIsDelete(!isDelete)}
        id={Number(id)}
        client_id={selectedClientId as number}
        triggerRender={() => setTrigger(new Date().getTime())}
      />
    </>
  );
};

export default AgencyDetails;
