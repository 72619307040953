import { useAppDispatch } from "app/hooks";
import { disableUser, enableUser } from "features";
import { useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

type Props = {
  isOpen: boolean;
  isDisable: boolean;
  selectedId: number;
  triggerRender: () => void;
  toggle: () => void;
};

export const EADisableUser = ({
  isOpen,
  isDisable,
  selectedId,
  triggerRender,
  toggle,
}: Props) => {
  const dispatch = useAppDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);

  const onSubmit = async () => {
    setIsSubmitting(true);
    try {
      if (isDisable) {
        await dispatch(disableUser(selectedId)).unwrap();
      } else {
        await dispatch(enableUser(selectedId)).unwrap();
      }
      triggerRender();
      toggle();
    } catch (error) {
      console.log("error: ", error);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>Confirm Disable</ModalHeader>
      <ModalBody>
        <p>
          Are you sure want to{" "}
          <span className="fw-bold">{isDisable ? "DISABLE" : "ENABLE"}</span>{" "}
          this user?
        </p>
      </ModalBody>
      <ModalFooter>
        <Button color="light" onClick={() => toggle()} disabled={isSubmitting}>
          Close
        </Button>
        <Button
          color="primary"
          onClick={() => onSubmit()}
          disabled={isSubmitting}
        >
          Yes
        </Button>
      </ModalFooter>
    </Modal>
  );
};
