import { configureStore } from "@reduxjs/toolkit";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import { allReducers } from "./allReducers";

const middleware: any = [];

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["auth"],
};

const persistedReducer = persistReducer(persistConfig, allReducers);

const storeConfig = () => {
  const store = configureStore({
    reducer: persistedReducer as typeof allReducers,
    middleware: (getDefaultMiddleware) => {
      const value = getDefaultMiddleware({
        serializableCheck: false,
      }).concat(middleware);
      return value;
    },
  });
  return store;
};

export const store = storeConfig();
export const persistor = persistStore(store);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
