import Api from "networking/api";
import { SubscriptionType } from "types";

export const getSubscriptionsAPI = () => {
  return Api.GET_SUBSCRIPTIONS<SubscriptionType[]>("");
};

export const getSubscriptionDetailsAPI = (id: number) => {
  return Api.GET_SUBSCRIPTION_DETAILS<SubscriptionType>("", {
    urlParams: {
      id,
    },
  });
};

export const createSubscriptionAPI = (data: SubscriptionType) => {
  return Api.CREATE_SUBSCRIPTION<{}>("", {
    data,
  });
};

export const updateSubscriptionAPI = (data: SubscriptionType) => {
  const { id, ...rest } = data;

  return Api.UPDATE_SUBSCRIPTION<{}>("", {
    urlParams: {
      id,
    },
    data: rest,
  });
};

export const enableSubscriptionAPI = (id: number) => {
  return Api.EAD_SUBSCRIPTION<{}>("", {
    urlParams: {
      id,
    },
    data: {
      status: "active",
    },
  });
};

export const disableSubscriptionAPI = (id: number) => {
  return Api.EAD_SUBSCRIPTION<{}>("", {
    urlParams: {
      id,
    },
    data: {
      status: "inactive",
    },
  });
};

export const deleteSubscriptionAPI = (id: number) => {
  return Api.DELETE_SUBSCRIPTION<{}>("", {
    urlParams: {
      id,
    },
  });
};
