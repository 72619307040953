import { useAppDispatch } from "app/hooks";
import { BreadCrumb } from "components";
import { PATH_NAME } from "constants/pathNames";
import { useFormik } from "formik";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
} from "reactstrap";
import * as Yup from "yup";

const ChangePassword = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showCFPassword, setShowCFPassword] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const validation = useFormik({
    initialValues: {
      old_password: "",
      password: "",
      confirm_password: "",
    },
    validationSchema: Yup.object({
      old_password: Yup.string().required("Please fill out this field."),
      password: Yup.string().required("Please fill out this field."),
      confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Passwords do not match.")
        .required("Please fill out this field."),
    }),
    onSubmit: (values) => {
      setIsSubmitting(true);
      // dispatch(registerUser(values));
    },
  });

  return (
    <div className="page-content">
      <Container fluid>
        <BreadCrumb title="Change Password" />
        <Row className="justify-content-center">
          <Col md={8} lg={6} xl={5}>
            <Card className="mt-4">
              <CardBody className="p-4">
                <Form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                  className="needs-validation"
                >
                  <div className="mb-3">
                    <Label className="required">Old Password</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        name="old_password"
                        type={showOldPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.old_password}
                      />
                      {validation.touched.old_password &&
                        validation.errors.old_password && (
                          <p className="text-danger mt-2">
                            {validation.errors.old_password}
                          </p>
                        )}
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                        onClick={() => setShowOldPassword(!showOldPassword)}
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </button>
                    </div>
                  </div>

                  <div className="mb-3">
                    <Label className="required">Password</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        name="password"
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.password}
                      />
                      {validation.touched.password &&
                        validation.errors.password && (
                          <p className="text-danger mt-2">
                            {validation.errors.password}
                          </p>
                        )}
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                        onClick={() => setShowPassword(!showPassword)}
                        type="button"
                        id="password-addon"
                      >
                        <i className="ri-eye-fill align-middle"></i>
                      </button>
                    </div>
                  </div>

                  <div className="mb-2">
                    <Label className="required">Confirm Password</Label>
                    <div className="position-relative auth-pass-inputgroup mb-3">
                      <Input
                        name="confirm_password"
                        type={showCFPassword ? "text" : "password"}
                        placeholder="Confirm Password"
                        onChange={validation.handleChange}
                        onBlur={validation.handleBlur}
                        value={validation.values.confirm_password}
                      />
                      {validation.touched.confirm_password &&
                        validation.errors.confirm_password && (
                          <p className="text-danger mt-2">
                            {validation.errors.confirm_password}
                          </p>
                        )}
                      <button
                        className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted shadow-none"
                        onClick={() => setShowCFPassword(!showCFPassword)}
                        type="button"
                        id="password-addon"
                      >
                        <i
                          className={`${
                            showCFPassword ? "ri-eye-off-fill" : "ri-eye-fill"
                          }  align-middle`}
                        ></i>
                      </button>
                    </div>
                  </div>

                  <div className="d-flex align-items-center justify-content-end gap-2">
                    <Button
                      color="secondary"
                      onClick={() => validation.handleSubmit()}
                      disabled={isSubmitting}
                    >
                      Update
                    </Button>
                    <Button
                      color="light"
                      onClick={() => navigate(PATH_NAME.DASHBOARD)}
                      disabled={isSubmitting}
                    >
                      Cancel
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ChangePassword;
