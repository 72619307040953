import { createSlice } from "@reduxjs/toolkit";
import { RedemptionSliceType } from "types";
import { getRedemptions } from "./thunk";

const initialState: RedemptionSliceType = {
  redemptionList: [],
};

export const redemptionSlice = createSlice({
  name: "redemption",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getRedemptions.fulfilled, (state, { payload }) => {
      state.redemptionList = payload;
    });
  },
});

export const redemptionReducer = redemptionSlice.reducer;
