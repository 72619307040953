import { combineReducers } from "@reduxjs/toolkit";
import {
  agencyReducer,
  authReducer,
  dashboardReducer,
  redemptionReducer,
  subscriptionReducer,
  userReducer,
} from "features";

export const allReducers = combineReducers({
  auth: authReducer,
  agency: agencyReducer,
  user: userReducer,
  subscription: subscriptionReducer,
  redemption: redemptionReducer,
  dashboard: dashboardReducer,
});
