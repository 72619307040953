import { PATH_NAME, SCREEN_NAME } from "constants/pathNames";
import { MenuDataTypes } from "./types";

export const MenuData: MenuDataTypes = [
  {
    id: SCREEN_NAME.DASHBOARD,
    label: SCREEN_NAME.DASHBOARD,
    icon: "ri-line-chart-line",
    link: PATH_NAME.DASHBOARD,
  },
  {
    id: SCREEN_NAME.USER_MANAGEMENT,
    label: SCREEN_NAME.USER_MANAGEMENT,
    icon: "ri-user-3-line",
    link: PATH_NAME.USER_MANAGEMENT,
  },
  {
    id: SCREEN_NAME.AGENCY_MANAGEMENT,
    label: SCREEN_NAME.AGENCY_MANAGEMENT,
    icon: "ri-group-line",
    link: PATH_NAME.AGENCY_MANAGEMENT,
  },
  {
    id: SCREEN_NAME.SUBSCRIPTION_MANAGEMENT,
    label: SCREEN_NAME.SUBSCRIPTION_MANAGEMENT,
    icon: "ri-pages-line",
    link: PATH_NAME.SUBSCRIPTION_MANAGEMENT,
  },
  {
    id: SCREEN_NAME.REDEMPTION_MANAGEMENT,
    label: SCREEN_NAME.REDEMPTION_MANAGEMENT,
    icon: "ri-settings-4-line",
    link: PATH_NAME.REDEMPTION_MANAGEMENT,
  },
];
