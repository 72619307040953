export const Endpoints = {
  // Auth
  LOGIN: "POST /sign_in",

  // Agency
  GET_AGENCIES: "GET /agencies",
  CREATE_AGENCY: "POST /agencies",
  UPDATE_AGENCY: "PUT /agencies/:id",
  DELETE_AGENCY: "DELETE /agencies/:id",
  GET_AGENCY_DETAILS: "GET /agencies/:id",
  GET_CLIENT_OF_AGENCY: "GET /agencies/:id/clients",
  UNLINK_CLIENT: "PUT /agencies/:id/unlink_client",
  DELETE_CLIENT: "DELETE /agencies/:id/destroy_client",

  // User
  GET_USERS: "GET /users",
  GET_USER_DETAILS: "GET /users/:id",
  CREATE_USER: "POST /users",
  UPDATE_USER: "PUT /users/:id",
  ENABLE_USER: "PUT /users/:id/enable",
  DISABLE_USER: "PUT /users/:id/disable",
  DELETE_USER: "DELETE /users/:id/destroy",

  // Campaign
  GET_CAMPAINS: "GET /campaigns",
  GET_CAMPAIN_DETAILS: "GET /campaigns/:id",
  GET_CAMPAIN_COSTS: "GET /campaigns/:id/campaign_costs",
  GET_CAMPAIN_RESULT: "GET /campaigns/:id/track_sales",
  DELETE_CAMPAIGN: "DELETE /campaigns/:id",

  // Subscription
  GET_SUBSCRIPTIONS: "GET /packages",
  GET_SUBSCRIPTION_DETAILS: "GET /packages/:id",
  CREATE_SUBSCRIPTION: "POST /packages",
  UPDATE_SUBSCRIPTION: "PUT /packages/:id",
  DELETE_SUBSCRIPTION: "DELETE /packages/:id",
  EAD_SUBSCRIPTION: "PUT /packages/:id/status",

  // Redemption
  GET_REDMEPTIONS: "GET /redemptions",
  GET_REDEMPTION_DETAILS: "GET /redemptions/:id",
  CREATE_REDEMPTION: "POST /redemptions",
  UPDATE_REDEMPTION: "PUT /redemptions/:id",
  DELETE_REDEMPTION: "DELETE /redemptions/:id",
  GET_REDEMPTION_CODES: "GET /redemptions/:id/codes",
  EXPORT_REDEMPTION_CODES: "GET /redemptions/:id/export_codes",

  // Dashboard
  GET_CAMPAIGN_RESULT_SUMMARY: "GET /dashboard/campaign_result_summary",
  GET_TOTAL_NEW_SUBSCRIBERS: "GET /dashboard/total_new_subscribers_per_month",
  GET_TOTAL_TERMINATED_SUBSCRIBERS:
    "GET /dashboard/total_terminated_subscribers_per_month",
  GET_TOTAL_FREE_TRIAL_SUBSCRIBERS:
    "GET /dashboard/total_free_trial_subscribers_per_month",
  GET_TOTAL_PAID_SUBSCRIBERS: "GET /dashboard/total_paid_subscribers_per_month",
  GET_TOTAL_DIY_SUBSCRIBERS:
    "GET /dashboard/total_diy_paid_subscribers_per_month",
  GET_TOTAL_INTEGRATION_SUBSCRIBERS:
    "GET /dashboard/total_integration_subscribers_per_month",
  GET_TOTAL_AGENCY_SUBSCRIBERS:
    "GET /dashboard/total_agency_subscribers_per_month",
  GET_TOTAL_AGENCY_CLIENTS: "GET /dashboard/total_agency_clients_per_month",
  GET_TOTAL_INACTIVE_SUBSCRIBERS:
    "GET /dashboard/total_inactive_subscribers_per_month",
  GET_TOTAL_CAMPAIGNS_FROM_SUBSCRIBERS:
    "GET /dashboard/total_campaigns_from_subscribers_per_month",
  GET_TOTAL_CAMPAIGN_SALES_FROM_SUBSCRIBERS:
    "GET /dashboard/total_campaigns_sales_from_subscribers_per_month",
  GET_TOP_SUBSCRIBERS_BY_SALES: "GET /dashboard/top_subscribers_by_sales",
  GET_TOP_SUBSCRIBERS_BY_PROFIT: "GET /dashboard/top_subscribers_by_profit",
  GET_TOTAL_CAMPAIGN_PROFIT_FROM_SUBSCRIBERS:
    "GET /dashboard/total_campaigns_profit_from_subscribers_per_month",
};
