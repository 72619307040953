import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  deleteClientAPI,
  getAgenciesAPI,
  getAgencyDetailsAPI,
  getClientsOfAgencyAPI,
  unlinkClientAPI,
} from "services";

export const getAgencies = createAsyncThunk("agency/getAgencies", async () => {
  try {
    const { data } = await getAgenciesAPI();
    return data;
  } catch (error: any) {
    console.log("error: ", error);
    throw new Error(error.message);
  }
});

export const getAgencyDetails = createAsyncThunk(
  "agency/getAgencyDetails",
  async (id: number) => {
    try {
      const { data } = await getAgencyDetailsAPI(id);
      return data;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getClientsOfAgency = createAsyncThunk(
  "agency/getClientsOfAgency",
  async (id: number) => {
    try {
      const { data } = await getClientsOfAgencyAPI(id);
      return data;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const unlinkClient = createAsyncThunk(
  "agency/unlinkClient",
  async (params: { id: number; client_id: number }) => {
    try {
      await unlinkClientAPI(params);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const deleteClient = createAsyncThunk(
  "agency/deleteClient",
  async (params: { id: number; client_id: number }) => {
    try {
      await deleteClientAPI(params);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);
