export const PATH_NAME = {
  DASHBOARD: "/",
  REPORT_DASHBOARD: "/report-dashboard",
  REPORT_CAMPAIGN_RESULT: "/report-campaign-result",
  USER_MANAGEMENT: "/user-management",
  CREATE_USER_MANAGEMENT: "/user-management/create",
  EDIT_USER_MANAGEMENT: "/user-management/edit/:id",
  AGENCY_MANAGEMENT: "/agency-management",
  AGENCY_DETAILS: "/agency-management/:id",
  SUBSCRIPTION_MANAGEMENT: "/subscription-management",
  CREATE_SUBSCRIPTION_MANAGEMENT: "/subscription-management/create",
  EDIT_SUBSCRIPTION_MANAGEMENT: "/subscription-management/edit/:id",
  REDEMPTION_MANAGEMENT: "/redemption-management",
  CREATE_REDEMPTION_MANAGEMENT: "/redemption-management/create",
  VIEW_REDEMPTION_MANAGEMENT: "/redemption-management/view/:id",
  EDIT_REDEMPTION_MANAGEMENT: "/redemption-management/edit/:id",
  PROFILE: "/profile",
  CHANGE_PASSWORD: "/change-password",
  LOGIN: "/login",
  REGISTER: "/register",
  FORGOT_PASSWORD: "/forgot-password",
};

export enum SCREEN_NAME {
  DASHBOARD = "Dashboard",
  USER_MANAGEMENT = "User Management",
  AGENCY_MANAGEMENT = "Agency Management",
  SUBSCRIPTION_MANAGEMENT = "Subscription Management",
  REDEMPTION_MANAGEMENT = "Redemption Management",
}
