import Api from "networking/api";
import { AgencyType } from "types";

export const getAgenciesAPI = () => {
  return Api.GET_AGENCIES<AgencyType[]>("");
};

export const getAgencyDetailsAPI = (id: number) => {
  return Api.GET_AGENCY_DETAILS<AgencyType>("", {
    urlParams: {
      id,
    },
  });
};

export const getClientsOfAgencyAPI = (id: number) => {
  return Api.GET_CLIENT_OF_AGENCY<AgencyType[]>("", {
    urlParams: {
      id,
    },
  });
};

export const unlinkClientAPI = (data: { id: number; client_id: number }) => {
  return Api.UNLINK_CLIENT<{}>("", {
    urlParams: {
      id: data.id,
    },
    data,
  });
};

export const deleteClientAPI = (data: { id: number; client_id: number }) => {
  return Api.DELETE_CLIENT<{}>("", {
    urlParams: {
      id: data.id,
    },
    data,
  });
};
