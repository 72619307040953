import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  createSubscriptionAPI,
  deleteSubscriptionAPI,
  disableSubscriptionAPI,
  enableSubscriptionAPI,
  getSubscriptionDetailsAPI,
  getSubscriptionsAPI,
  updateSubscriptionAPI,
} from "services";
import { SubscriptionType } from "types";

export const getSubscriptions = createAsyncThunk(
  "subscription/getSubscriptions",
  async () => {
    try {
      const { data } = await getSubscriptionsAPI();
      return data;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const getSubscriptionDetails = createAsyncThunk(
  "subscription/getSubscriptionDetails",
  async (id: number) => {
    try {
      const { data } = await getSubscriptionDetailsAPI(id);
      return data;
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const createSubscription = createAsyncThunk(
  "subscription/createSubscription",
  async (data: SubscriptionType) => {
    try {
      await createSubscriptionAPI(data);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const updateSubscription = createAsyncThunk(
  "subscription/updateSubscription",
  async (data: SubscriptionType) => {
    try {
      await updateSubscriptionAPI(data);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const enableSubscription = createAsyncThunk(
  "subscription/enableSubscription",
  async (id: number) => {
    try {
      await enableSubscriptionAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const disableSubscription = createAsyncThunk(
  "subscription/disableSubscription",
  async (id: number) => {
    try {
      await disableSubscriptionAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);

export const deleteSubscription = createAsyncThunk(
  "subscription/deleteSubscription",
  async (id: number) => {
    try {
      await deleteSubscriptionAPI(id);
    } catch (error: any) {
      console.log("error: ", error);
      throw new Error(error.message);
    }
  }
);
